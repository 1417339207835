import React from "react";
import Web from "../../containers/services/Web";
import { graphql } from 'gatsby';

const ServicesWebPagePreviewTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <Web data={frontmatter} />;
};

export default ServicesWebPagePreviewTemplate;

export const pageQuery = graphql`
  query ServicesWebPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        heading
        description
        rightList
        leftList
        others
      }
    }
  }
`;
