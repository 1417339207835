import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TabBar from "../../components/TabBar";
import { servicesFields } from "../../metadata";
import Grid from "@material-ui/core/Grid";
import { Link, withPrefix } from "gatsby";
import classnames from "classnames";
import Layout from '../../components/layout';

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 22,
      marginRight: theme.spacing.unit * 22,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
    }
  },
  subheading: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem"
    }
  },
  article: {
    ...theme.typography.article,
    textTransform: "uppercase",
    paddingTop: theme.spacing.unit * 3
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  build: {
    ...theme.typography.paragraph,
    lineHeight: "1.5",
    fontSize: "16px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    }
  },
  buildItem: {
    width: "250px",
    marginBottom: "40px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  technologies: {
    paddingLeft: "20px"
  },
  container: { marginTop: "44px", marginBottom: "44px" },
  box: {
    border: "1px solid #959595",
    padding: "30px 20px",
    marginTop: theme.spacing.unit * 6
  },
  boxHeading: {
    ...theme.typography.subheading,
    fontSize: "30px",
    color: "rgba(37, 54, 156, 255)",
    fontWeight: "400",
    textTransform: "uppercase"
  },
});

const buildItems = [
  {
    icon: withPrefix("icons/web/large-enterprise.svg"),
    text: 'Large enterprise scale frictionless shopping experiences'
  },  {
    icon: withPrefix("icons/web/clienteling.svg"),
    text: 'Clientelling interfaces and services'
  },  {
    icon: withPrefix("icons/web/web-services.svg"),
    text: 'Enterprise web services'
  },  {
    icon: withPrefix("icons/web/frictionless_shopping.svg"),
    text: 'Frictionless shopping experiences'
  },  {
    icon: withPrefix("icons/web/saas.svg"),
    text: 'SaaS applications'
  },  {
    icon: withPrefix("icons/web/crm.svg"),
    text: 'Custom CRM products'
  },
];

const Web = ({ classes, location }) => (
  <Layout location={location}>
    <React.Fragment>
    <TabBar fields={servicesFields} />
    <div className={classes.root}>
      <div className={classes.subheading}>CUSTOM WEB DEVELOPMENT</div>
      <div className={classes.paragraph}>
        We’ve seen web design and development evolve, becoming
        progressively more robust and more complex, and even going above
        and beyond what's possible in desktop software. Web is both art
        and science and we've been at it from the beginning.
      </div>
      <div className={classes.paragraph}>
        Maybe you're looking to update or refine your legacy and aging
        applications or perhaps you are toying with the idea of brand-new
        web application with enterprise level microservices architecture
        or integration with some SaaS application. Whatever your needs,
        Primoko can help.
      </div>
      <Grid container justify="space-between">
        <Grid item sm={12} md={7}>
          <div className={classnames(classes.subheading, classes.container)}>
            We’ve BUILT…
          </div>
          <ul className={classes.build}>
            {buildItems.map(({icon, text}, index) =>
              <li className={classes.buildItem} key={index}>
                <img
                  src={icon}
                  width="30"
                  height="30"
                />
                <span className={classes.paragraph}>{text}</span>
              </li>
            )}
          </ul>
        </Grid>
        <Grid item sm={12} md={4}>
          <div className={classes.box}>
            <div className={classes.boxHeading}>We speak web</div>
            <div className={classes.paragraph}>
              Depending on the stage of your project, we can work with your
              technology stack or advise and guide you in selecting the right
              stack for your requirements and objectives.
              Recent projects have used:
            </div>
            <Grid container alignItems="center" justify="space-between">
              <ul className={classes.technologies}>
                <li className={classes.paragraph}>
                  PHP (LAMP), .NET, Node.js. (MEAN) Python, Django, Pyramid,
                  Ruby (on Rails), ASP.NET Code 2.0 MVC, Entity Framework
                  Code 2.0., Restify, React.JS, Express.JS. Backbone, Angular
                </li>
                <li className={classes.paragraph}>
                  Oracle ATG Commerce Salesforce Commerce Cloud
                  (Demandware), SAP Hybris, Magento
                </li>
                <li className={classes.paragraph}>
                  Enterprise proprietary platforms and 100s of third-party
                  integrations
                </li>
              </ul>
            </Grid>
            <div className={classes.paragraph}>
              Need a different technology?
              <br/>
              <Link to='/letstalk'>Contact us</Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  </React.Fragment>
  </Layout>
);

export default withStyles(styles)(Web);
